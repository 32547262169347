


















import { defineComponent, onBeforeMount } from '@vue/composition-api';
import ProgressCircularComponent from '@/components/Common/molecules/ProgressCircular.vue';
import { useTenantDetailProgressCircular } from '@/composables/store/Management/useTenantDetailProgressCircular';
import TenantDetailTableComponent from '@/components/Management/organisms/TenantDetailTable.vue';
import { useTenantDetail } from '@/composables/store/Management/useTenantDetail';
import TenantDetailScheduleComponent from '@/components/Management/organisms/TenantDetailSchedule.vue';

export default defineComponent({
    components: {
        ProgressCircular: ProgressCircularComponent,
        TenantDetailTable: TenantDetailTableComponent,
        TenantDetailSchedule: TenantDetailScheduleComponent,
    },
    setup(props, context) {
        const { isTenantDetailProgressing } = useTenantDetailProgressCircular();

        const { error, fetchDetail } = useTenantDetail();

        const route = context.root.$route;

        onBeforeMount((): void => {
            fetchDetail(parseInt(route.params.tenantId));
        });

        return {
            error,
            isTenantDetailProgressing,
        };
    },
});
