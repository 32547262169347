





























import { defineComponent } from '@vue/composition-api';
import ProgressCircularComponent from '@/components/Common/molecules/ProgressCircular.vue';
import { useTenantDetail } from '@/composables/store/Management/useTenantDetail';

export default defineComponent({
    components: {
        ProgressCircular: ProgressCircularComponent,
    },
    setup() {
        const { tenantDetail } = useTenantDetail();

        return {
            tenantDetail,
        };
    },
});
