import { ADMINISTRATOR_ROLE_ID, OPERATOR_ROLE_ID, RoleType } from '@/models/interface/Management/ITenantMRepository';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useRoleTypeLabel = () => {
    const getLabel = (type: RoleType): string => {
        if (type === ADMINISTRATOR_ROLE_ID) return '管理者';
        if (type === OPERATOR_ROLE_ID) return 'OP';
        return '権限無し';
    };

    return {
        getLabel,
    };
};
