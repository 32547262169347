

























































































































import { defineComponent } from '@vue/composition-api/dist/vue-composition-api';
import CopyAlertComponent from '@/components/Management/molecules/CopyAlert.vue';
import { useTenantDetail } from '@/composables/store/Management/useTenantDetail';
import { useRoleTypeLabel } from '@/composables/function/Management/useRoleTypeLabel';

export default defineComponent({
    components: {
        CopyAlert: CopyAlertComponent,
    },
    setup() {
        const { tenantDetail, copyFlag, copy } = useTenantDetail();
        const { getLabel } = useRoleTypeLabel();

        const statsServerValue = (): string => {
            return tenantDetail.value.statsServer.name
                ? tenantDetail.value.statsServer.domain + '(' + tenantDetail.value.statsServer.name + ')'
                : tenantDetail.value.statsServer.domain;
        };

        return {
            tenantDetail,
            copyFlag,
            statsServerValue,
            getLabel,
            copy,
        };
    },
});
